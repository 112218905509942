<template>
  <div class="gps-box">
    <div class="gps-search">
      <div class="gps-item" v-for="(it, idx) in searchCols" :key="`a` + idx">
        <el-input
          v-if="it.type == 'ipt'"
          v-model="it.val"
          :placeholder="it.title"
          clearable
        ></el-input>
        <el-date-picker
          clearable
          v-if="it.type == 'dtr'"
          v-model="it.val"
          type="datetimerange"
          range-separator="To"
          :start-placeholder="it.title_s"
          :end-placeholder="it.title_e"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
        <el-select
          clearable
          v-if="it.type == 'sel'"
          v-model="it.val"
          :placeholder="it.title"
          @change="it.change && it.change($event, callbackFun)"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-select
          clearable
          v-if="it.type == 'msel'"
          multiple
          v-model="it.val"
          :placeholder="it.title"
        >
          <el-option
            v-for="item in it.data"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <el-button
          v-if="it.type == 'btn'"
          @click="it.click && it.click(callbackFun)"
          :type="it.btnType"
          :icon="it.icon"
          round
          >{{ it.title }}</el-button
        >
        <el-upload
          v-if="it.type == 'upload'"
          class="upload-demo"
          :action="it.url"
          name="excelFile"
          :data="uploadData"
          :limit="1"
          :on-success="handleUpload"
          :show-file-list="false"
        >
          <el-button :type="it.btnType" :icon="it.icon" round>{{
            it.title
          }}</el-button>
        </el-upload>
      </div>
    </div>
    <div class="gps-body">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClass"
      >
        <el-table-column type="selection" width="30" v-if="props.selection" />
        <el-table-column
          :label="cls.name"
          :width="cls.width"
          v-for="cls in tableCols"
          :key="cls.filed"
        >
          <template #default="{ row }">
            <span v-if="cls.render" v-html="cls.render(row)"></span>
            <span v-else>{{ row[cls.filed] }}</span>
          </template>
        </el-table-column>

        <el-table-column
          fixed="left"
          :label="$t('commKey.Actions')"
          :width="props.opWidth + 'px'"
          v-if="tableOps.length > 0"
        >
          <template #default="{ row }">
            <span class="gps-span" v-for="(op, idx) in tableOps" :key="idx">
              <el-button
                v-if="op.opType == 'btn'"
                :icon="op.icon"
                round
                :type="op.type"
                @click="op.click && op.click(row, callbackFun)"
                >{{ op.name }}</el-button
              >
              <el-popconfirm
                :title="$t('commKey.CONFIRMTITLE')"
                v-if="op.opType == 'del'"
                @confirm="op.click && op.click(row, callbackFun)"
              >
                <template #reference>
                  <el-button :icon="op.icon" round :type="op.type">{{
                    op.name
                  }}</el-button>
                </template>
              </el-popconfirm>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="gps-page">
      <el-pagination
        :currentPage="query.page"
        :page-size="query.rp"
        :page-sizes="[20, 50, 100, 200, 300, 400]"
        :disabled="disabled"
        :background="background"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  computed,
  defineEmits,
  toRaw,
  onMounted,
} from "vue";
import { useI18n } from "vue-i18n";
import signObj from "../../../utils/upload-sign";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  searchCols: {
    type: Array,
    default: () => [],
  },
  tableData: {
    type: Array,
    default: () => [],
  },
  tableCols: {
    type: Array,
    default: () => [],
  },
  tableOps: {
    type: Array,
    default: () => [],
  },
  query: {
    type: Object,
    default: () => {},
  },
  total: {
    type: Number,
    default: 0,
  },
  selection: {
    type: Boolean,
    default: false,
  },
  opWidth: {
    type: Number,
    default: 170,
  },
});

const background = ref(false);
const disabled = ref(false);

const emit = defineEmits(["callbackEvent", "handleCurrentChange"]);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  emit("handleCurrentChange", val);
};

const callbackFun = (obj) => {
  let attr_search = toRaw(props.searchCols);
  let attr = {};
  attr_search.forEach((it) => {
    if (it.type != "btn") {
      switch (it.type) {
        case "dtr":
        case "msel":
          if (it.key) attr[it.key] = it.val.join(",");
          break;

        default:
          if (it.key) attr[it.key] = it.val;
          break;
      }
    }
  });

  obj.search = attr;

  emit("callbackEvent", obj);
};

const uploadData = reactive(signObj);
const handleUpload = (response, uploadFile, uploadFiles) => {
  emit("callbackEvent", Object.assign({ type: "upload" }, response));
};

const get_heigth = () => {
  let h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return parseFloat(h) - 153;
};
const tableHeight = ref(300);
onMounted(() => {
  tableHeight.value = get_heigth();

  window.onresize = () => {
    tableHeight.value = get_heigth();
  };
});
//////////////////////////////////////////
//多选处理
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  emit("callbackEvent", { type: "multipleSelection", rows: val });
};

const tableRowClass = ({ row, rowIndex }) => {
  if (row.is_red && row.is_red == 1) {
    return "row-red";
  }
  return "";
};
</script>

<style lang="scss" scoped>
::v-deep(.el-table .cell .gps-span) {
  // display: inline-block;
  margin-right: 5px;
}
.gps-box {
  box-sizing: border-box;
  padding: 0px;
  .gps-search {
    display: flex;
    flex-direction: row;
    .gps-item {
      box-sizing: border-box;
      padding-right: 10px;
    }
  }
}
::v-deep .el-input.el-input--suffix {
  // 固定宽度
  width: 200px !important;
}
</style>
